import {autoinject, bindable, TaskQueue} from "aurelia-framework";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {FhirService} from "../../services/FhirService";
import * as moment from 'moment';
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {I18N} from "aurelia-i18n";
import {App} from "../../../app";
import {fhirEnums} from "../../classes/fhir-enums";
import ResourceType = fhirEnums.ResourceType;
import {WoundDataHandler} from "../../../views/patient/wunden/wound-data-handler";

@autoinject
export class ImageList {
    @bindable thumbs: any[];
    @bindable sliderPos: number = 0;
    @bindable patient: PatientItem;
    @bindable selectedEncounter: ImageListEncounterItem;
    @bindable woundId: string;
    @bindable encounterId: string;
    @bindable selectedWound: any;
    @bindable selectedGroup: any;
    @bindable selectedThumb: any;

    currentGroups: any[];
    encounters: ImageListEncounterItem[];
    loadingEncounters: boolean = true;
    pos: number = 0;
    images: IHistoryImageItem[] = [];
    selectedImage: IHistoryImageItem;
    hasImages: boolean = false;
    loadingImages: boolean = true;
    targetGroup;
    targetWound;
    thumbList: any[];
    protected dataHandler: WoundDataHandler;
    public static imageCache: any;
    protected fhirService: FhirService;

    constructor(protected i18n: I18N, protected queue: TaskQueue) {
        this.fhirService = new FhirService(FhirService.Endpoint);
        this.dataHandler = new WoundDataHandler(i18n);
    }

    attached() {
    }

    async selectedEncounterChanged(newValue: ImageListEncounterItem) {
        if (newValue) {
            this.loadingImages = true;
            this.currentGroups = await this.dataHandler.loadWounds(newValue.encounterId);
            if (this.currentGroups.length === 0) {
                this.loadingEncounters = false;
                this.loadingImages = false;

                return;
            }

            if (!this.targetGroup) {
                this.targetWound = undefined;
                this.targetGroup = this.currentGroups.find(o => o.children && typeof o.children.find(c => (c.item && c.item.id === this.woundId) || c.listId === this.woundId) !== "undefined");

                if (this.targetGroup) {
                    this.selectedGroup = this.targetGroup;
                    for (const item of this.targetGroup.children) {
                        if (item.listId === this.woundId) this.targetWound = item;
                        else if (item.item && item.item.id === this.woundId) this.targetWound = item;

                        if (this.targetWound) {
                            break;
                        }
                    }
                }
            }
        } else {
            this.loadingEncounters = false;
            this.loadingImages = false;
        }
    }

    async selectedWoundChanged(item) {
        try {
            this.loadingImages = true;
            await this.dataHandler.loadThumbNails(this.selectedEncounter.encounterId, item);

            this.thumbList = this.dataHandler.thumbNailList;
            this.hasImages = this.thumbList.length > 0;
            this.loadingImages = false;

            if (this.thumbList && this.thumbList.length > 0) {
                this.selectedThumb = this.thumbList[0];
            } else {
                this.selectedThumb = undefined;
            }
        }
        catch (ex) {
            console.warn(ex);
        }
        finally {
            this.loadingImages = false;
        }
    }

    selectedGroupChanged(group) {
        try {
            this.loadingImages = true;

            if (this.targetGroup && this.targetWound) {
                this.queue.queueTask(() => {
                    this.selectedWound = this.targetWound;

                    this.queue.queueTask(() => {
                        this.woundId = undefined;
                        this.encounterId = undefined;
                        this.targetWound = undefined;
                        this.targetGroup = undefined;
                    });
                });
            } else {
                this.loadingImages = false;
            }
        }
        catch (ex) {
            this.loadingImages = false;
            console.warn(ex);
        }        
    }

    async patientChanged(newValue: PatientItem) {
        if (newValue) {
            this.loadingEncounters = true;
            this.encounters = await this.loadEncounters();

            const enc = this.encounters.find(o => o.encounterId === this.encounterId);
            this.selectedEncounter = enc || this.encounters[0];
            this.loadingEncounters = false;
            this.encounterId = undefined;
        } else {
            this.loadingEncounters = false;
        }
    }

    async loadEncounters(): Promise<ImageListEncounterItem[]> {
        // const url = `Encounter?subject=Patient/${this.patient.id}`;
        const url = `Encounter?subject=Patient/${this.patient.id}&_revinclude=Observation:${FhirService.FhirVersion >= 4 ? 'encounter' : 'context'}`;
        const resources: any[] = await this.fhirService.fetch(url, true);
        const encounters = <any[]>resources.filter(o => o.resourceType === ResourceType.encounter);
        const allObservations = <any[]>resources.filter(o => o.resourceType === ResourceType.observation);
        const result: ImageListEncounterItem[] = [];

        for (const encounter of encounters) {
            const observations = allObservations.filter(o => o.context && o.context.reference && o.context.reference.endsWith('/' + encounter.id));
            const item = ImageListEncounterItem.FromEncounter(encounter, this.i18n);
            item.observations = observations;
            result.push(item);
        }

        return result;
    }

    sliderPosChanged(pos) {
        this.pos = parseInt(pos);
    }
}

export class ImageListEncounterItem {
    public encounterId: string;
    public display: string;
    public observations: any[];

    public static ReadWounds(observations: any[]): any[] {
        // first: get a distinct list of all wound categories
        const categories: string[] = [];
        const result: any[] = [];

        for (const observation of observations) {
            if (observation.category && observation.category[0]) {
                const cat = observation.category[0].text;
                if (cat && categories.indexOf(cat) === -1) {
                    categories.push(cat);
                }
            }
        }
        categories.sort();

        // now get all wounds for the categories
        for (const category of categories) {
            const currentObservations = observations.filter(o => o.category && o.category[0] && o.category[0].text === category);
            for (const obs of currentObservations) {
                let number: string = '?';
                let bodySite: string = '?';
                let start: string = '?';
                let end: string = '?';

                if (obs.identifier && obs.identifier[0] && obs.identifier[0].value)
                    number = obs.identifier[0].value;

                if (obs.bodySite && obs.bodySite.coding && obs.bodySite.coding[0] && obs.bodySite.coding[0].display) {
                    bodySite = App.i18n.tr(obs.bodySite.coding[0].display);
                }

                if (obs.effectivePeriod) {
                    if (obs.effectivePeriod.start) {
                        start = moment(obs.effectivePeriod.start).format(RuntimeInfo.DateFormat);
                    }

                    if (obs.effectivePeriod.end) {
                        end = moment(obs.effectivePeriod.end).format(RuntimeInfo.DateFormat);
                    }
                }

                const date = [start, end].filter(o => o).join(' - ');

                obs.text = {
                    div: `${bodySite} (${number ? '#' + number : ''}, ${date})`,
                    status: 'generated'
                };
            }

            const cat = {
                id: '-',
                display: String(App.i18n.tr(category)),
                type: "group",
                wounds: currentObservations
            };


            result.push(cat);
        }

        return result;
    }

    public static FromEncounter(encounter: any, i18n : I18N): ImageListEncounterItem {
        const result: ImageListEncounterItem = new ImageListEncounterItem({encounterId: encounter.id});
        let visitNumber: string = '';
        let start: string = '';
        let end: string = '';
        let status: string = '';

        if (encounter.identifier) {
            const vn = encounter.identifier.find(o => o.system?.indexOf('/visitNumber') > -1);
            if (vn?.value) {
                visitNumber = String(vn.value);
            }
        }

        if (!visitNumber || visitNumber === "undefined") {
            visitNumber = i18n.tr("no_visit_number");
        }

        if (encounter.period) {
            if (encounter.period.start) {
                start = moment(encounter.period.start).format(RuntimeInfo.DateFormat);
            } else start = '?';

            if (encounter.period.end) {
                end = moment(encounter.period.end).format(RuntimeInfo.DateFormat);
            } else end = '?';
        }

        if (encounter.status) {
            status = i18n.tr(encounter.status);
        }

        result.display = `${visitNumber} (${start}, ${end}, ${status})`;

        return result;
    }

    constructor(data?) {
        if (data) Object.assign(this, data);
    }
}

export interface IHistoryImageItem {
    thumb: any;
    image: any;
    created: string;
    id?: string;
}
