import { bindable } from "aurelia-framework";
import * as Fhir from "../../classes/FhirModules/Fhir";
import { fhirEnums } from "../../classes/fhir-enums";
import { NitTools } from "../../classes/NursitTools";
import { RuntimeInfo } from "../../classes/RuntimeInfo";
import { App } from "app";
import { textblockDialog } from "./textblock-dialog";
import { QuestionnaireService } from "resources/services/QuestionnaireService";
import { Questionnaire } from "./questionnaire";

export class qInput {
    @bindable response: any;
    @bindable item;
    @bindable changed: Function;
    @bindable readonly: boolean;
    @bindable hidePlaceholder: boolean;
    @bindable qitem;
    @bindable element: HTMLDivElement;
    @bindable hasTextBlock: boolean;

    inputType: string = "text";
    readonlyBySetting: boolean = false;
    required: boolean = false;
    _isCalulcatedField: boolean = undefined;
    responseItem: any = undefined;
    mouseEventsBound: boolean = false;
    menu: HTMLDivElement;

    hidePopupMenu() {
        document.body.querySelector('.textblock-hash-popup-menu')?.remove();
        this.menu = undefined;
    }

    selectElementContents(el) {
        if (window.getSelection && document.createRange) {
            var sel = window.getSelection();
            var range = document.createRange();
            range.selectNodeContents(el);
            sel.removeAllRanges();
            sel.addRange(range);

            return sel;
        } else if (document["selection"] && document.body["createTextRange"]) {
            var textRange = document.body["createTextRange"]();
            textRange.moveToElementText(el);
            textRange.select();
            return textRange;
        }
    }

    textarea : HTMLTextAreaElement;

    textareaKeyPressed($event) {
        const ele: HTMLTextAreaElement = $event.target || event.srcElement || this.textarea;
        if (!this.hasTextBlock || !ele) return true;

        // keyboard navigation in the popup-menu, arrow up/down, escape, enter, akways returns false
        if (this.menu && [13,27,38,40].indexOf($event.keyCode) > -1) {
            const currentElement : HTMLLIElement = this.menu.querySelector('li.selected[data-index]');
            const count = this.menu.querySelectorAll('li[data-index]').length -1;
            let idx = NaN;
            if (currentElement)
            {
                idx = parseInt(currentElement.getAttribute('data-index'));
                currentElement.classList.remove('selected');
            }

            if (isNaN(idx) || idx < 0)  idx = 0;

            let newElement : HTMLLIElement;
            switch ($event.keyCode) {
                case 13:
                    newElement = this.menu.querySelector(`li[data-index="${idx}"]`);
                    if (newElement)
                        $(newElement).trigger('click');

                    return false;
                case 27:    // escape
                    this.hidePopupMenu();
                case 38:    // up
                        idx--;
                    break;
                case 40: // down
                        idx++;                        
                break;
            }

            if (idx < 0) idx = 0;
            else if (idx > count) idx = count;

            newElement = this.menu?.querySelector(`li[data-index="${idx}"]`);
            if (newElement) {
                newElement.classList.add('selected');
                newElement.scrollIntoView(false);
            }

            return false;
        }

        // open menu when # is typed
        if ($event.key != '#' || ele?.tagName != 'TEXTAREA') return true;
        let showMenu = true;
        if (ele.selectionStart > 1) {
            // only show popup when the prior char is a blank
            const priorChar = ele.value[ele.selectionStart - 1];
            showMenu = priorChar === ' ';
        } else {
            // when no text at all exists
            showMenu = true;
        }

        if (!showMenu) return true;
        const coords = this.getCaretCoordinates(ele, ele.selectionStart); // get the xy-location of the caret
        const basePosition = $(this.element).offset();

        this.menu = document.createElement('div');
        this.menu.classList.add('textblock-hash-popup-menu');
        this.menu.style.left = `${basePosition.left + coords.left + 12}px`;
        this.menu.style.top = `${basePosition.top + coords.top}px`;
        this.menu.innerHTML = '<img src="images/loading.gif" style="width: 1em; height: 1em" />';
        
        document.body.appendChild(this.menu);

        var model = new textblockDialog(undefined, App.i18n);
        const questionnaire = QuestionnaireService.GetQuestionnaireDirect(this.response.questionnaire);
        model.activate({
            questionnaire: questionnaire,
            response: this.response,
            questionnaireItem: this.item,
            responseItem: this.responseItem,
            formElement: this.element,
        })

        const textBlocks = model.textblocks.sort((a, b) => {
            return a?.code?.localeCompare(b.code);
        });

        if (textBlocks?.length <= 0) {
            this.hasTextBlock = false;
            this.menu = undefined;

            return true;
        }

        let list = document.createElement('ul');
        const _that = this; // for click-handlers

        // add an item for abort
        const li = document.createElement('li');
        li.classList.add('text-block-popup-item-close');
        li.innerHTML = `${App?.i18n?.tr('abort')} <kbd>Esc</kbd>`;
        li.onclick = (ev) => {
            _that.hidePopupMenu();
        };
        list.appendChild(li);

        // add all existing textblocks to the list without category
        for (const tb of textBlocks) {
            const li = document.createElement('li');
            li.setAttribute("data-index", textBlocks.indexOf(tb).toString());
            li.classList.add('text-block-popup-item');
            li.onclick = (ev) => {
                model.selectEffectiveTextblock(tb); // get the result from the model
                let str = ele.value; // current value of the textarea
                let start = ele.selectionStart; // the current caret position
                let s1 = str.substring(0, start -1).trim(); // remove # and split the text if needed
                let s2 = str.substring(start).trim();
                const sResult = String(model.result).trim();
                ele.value = `${s1} ${sResult} ${s2}`.trim();

                if (!s2) // when no string after the second part add a space for easier typing
                    ele.value += ' ';

                // trim to max-length if present
                if (ele.value && this.item?.maxLength && ele.value.length > this.item.maxLength) {
                    ele.value = ele.value.substring(0, this.item.maxLength);
                }

                // remove the popup
                _that.hidePopupMenu();

                // resize the memo
                _that?.adjustHeight();
                
                // re-focus the element and put the caret behind the text
                ele.focus();
                ele.selectionStart = start + sResult.length;
                ele.selectionEnd = ele.selectionStart; 
            }

            li.innerText = tb.code;
            list.appendChild(li);
        }

        this.menu.innerHTML = '';
        this.menu.appendChild(list);

        // mark the 1st textblock as selected
        this.menu.querySelector(`li[data-index="0"]`)?.classList.add('selected');

        // ensure the menu is not out of screen..

        // .. to the right ..
        const curPos = $(this.menu).offset();
        const curW = $(this.menu).outerWidth() + 20;
        // const curH = $(this.menu).outerWidth() + 20;
        if ($(document.body).innerWidth() < curPos.left + curW) {
            const newX = $(document.body).innerWidth() - curW;
            this.menu.style.left = `${newX - 24}px`;
        } 

        // .. to bottom
        const scrollTop = document.body.clientHeight;
        const bottom = $(this.menu).offset().top + $(this.menu).height();
        if (bottom > scrollTop) {
            const y = scrollTop -  $(this.menu).height() - 24;
            this.menu.style.top = `${y}px`;
        }

        return true;
    }


    getCaretCoordinates(element, position) {
        const properties = [
            'boxSizing',
            'width',  // on Chrome and IE, exclude the scrollbar, so the mirror div wraps exactly as the textarea does
            'height',
            'overflowX',
            'overflowY',  // copy the scrollbar for IE

            'borderTopWidth',
            'borderRightWidth',
            'borderBottomWidth',
            'borderLeftWidth',

            'paddingTop',
            'paddingRight',
            'paddingBottom',
            'paddingLeft',

            // https://developer.mozilla.org/en-US/docs/Web/CSS/font
            'fontStyle',
            'fontVariant',
            'fontWeight',
            'fontStretch',
            'fontSize',
            'lineHeight',
            'fontFamily',

            'textAlign',
            'textTransform',
            'textIndent',
            'textDecoration',  // might not make a difference, but better be safe

            'letterSpacing',
            'wordSpacing'
        ];

        const isFirefox = !!(window["mozInnerScreenX"]);
        // mirrored div
        let mirrorDiv = document.getElementById(element.nodeName + '--mirror-div');

        if (mirrorDiv) mirrorDiv.remove();
        mirrorDiv = document.createElement('div');
        mirrorDiv.id = element.nodeName + '--mirror-div';
        document.body.appendChild(mirrorDiv);
        let style = mirrorDiv.style;
        let computed = getComputedStyle(element);

        // default textarea styles
        style.whiteSpace = 'pre-wrap';
        if (element.nodeName !== 'INPUT')
            style.wordWrap = 'break-word';  // only for textarea-s

        // position off-screen
        style.position = 'absolute';  // required to return coordinates properly
        style.top = element.offsetTop + parseInt(computed.borderTopWidth) + 'px';
        style.left = "400px";
        style.visibility = 'hidden';  // not 'display: none' because we want rendering

        // transfer the element's properties to the div
        properties.forEach(function (prop) {
            style[prop] = computed[prop];
        });

        if (isFirefox) {
            style.width = parseInt(computed.width) - 2 + 'px'  // Firefox adds 2 pixels to the padding - https://bugzilla.mozilla.org/show_bug.cgi?id=753662
            // Firefox lies about the overflow property for textareas: https://bugzilla.mozilla.org/show_bug.cgi?id=984275
            if (element.scrollHeight > parseInt(computed.height))
                style.overflowY = 'scroll';
        } else {
            style.overflow = 'hidden';  // for Chrome to not render a scrollbar; IE keeps overflowY = 'scroll'
        }

        mirrorDiv.textContent = element.value.substring(0, position);
        // the second special handling for input type="text" vs textarea: spaces need to be replaced with non-breaking spaces - http://stackoverflow.com/a/13402035/1269037
        if (element.nodeName === 'INPUT')
            mirrorDiv.textContent = mirrorDiv.textContent.replace(/\s/g, "\u00a0");

        var span = document.createElement('span');
        // Wrapping must be replicated *exactly*, including when a long word gets
        // onto the next line, with whitespace at the end of the line before (#7).
        // The  *only* reliable way to do that is to copy the *entire* rest of the
        // textarea's content into the <span> created at the caret position.
        // for inputs, just '.' would be enough, but why bother?
        span.textContent = element.value.substring(position) || '.';  // || because a completely empty faux span doesn't render at all
        span.style.backgroundColor = "lightgrey";
        mirrorDiv.appendChild(span);

        var coordinates = {
            top: span.offsetTop + parseInt(computed['borderTopWidth']),
            left: span.offsetLeft + parseInt(computed['borderLeftWidth'])
        };

        mirrorDiv.remove();

        return coordinates;
    }

    get placeHolderText(): string {
        if (this.hidePlaceholder) return "";
        return this.item ? this.item.text : "";
    }

    get value() {
        let result = '';

        if (this.responseItem && this.item) {
            if (this.responseItem.answer && this.responseItem.answer.length > 0) {
                result = Fhir.QuestionnaireResponse.GetResponseItemValue(this.responseItem);
                if (typeof result === "string" && (String(result)).endsWith('_nil')) {
                    result = '';
                }
            }
        }

        return result;
    }

    set value(newValue) {
        const curVal = String(Fhir.QuestionnaireResponse.GetResponseItemValue(this.responseItem) || '');
        if (String(newValue || '') == curVal || (!newValue && curVal === 'NaN') || (newValue === 'NaN' && curVal === '')) return;

        if (!this.responseItem && this.item && this.response) {
            this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId);
        }

        if (!this.responseItem) return;

        if (typeof newValue === "undefined" || newValue === '' || ((typeof newValue === "string") && (String(newValue).endsWith('_nil')))) {
            delete this.responseItem.answer;
        } else {
            let itemAnswer: any = {
                valueString: newValue
            };

            if (this.item) {
                if (this.item.type === 'integer') {
                    itemAnswer = {
                        valueInteger: parseInt(newValue)
                    };
                } else if (this.item.type === 'decimal') {
                    itemAnswer = {
                        valueDecimal: parseFloat(newValue),
                    };
                } else if (this.item.type === 'url') {
                    itemAnswer = {
                        valueUri: String(newValue),
                    };
                }
            }

            this.responseItem.answer = [itemAnswer];
        }

        this.adjustHeight();

        if (typeof this.changed === "function") {
            // goes down to questionnaire.onValueChanged
            this.changed(this.responseItem);
        }
    }

    previousHeight: number = 60;
    adjustHeight() {
        if (!this.element || this.item?.type !== 'text') return true;
        try {
            const textarea = this.element.querySelector('textarea');

            if (textarea) {
                this.setHeight(textarea, {
                    restoreTextAlign: false,
                    testForHeightReduction: true,
                });
            }
        }
        catch (ex) {
            console.warn('When resizing TextArea:', ex);
        }

        return true;
    }

    setHeight(ta: HTMLTextAreaElement, {
        restoreTextAlign = null,
        testForHeightReduction = true,
    }) {
        if (!ta || !window.getComputedStyle) return;
        const computed = window.getComputedStyle(ta);
        let initialOverflowY = computed.overflowY;

        if (ta.scrollHeight === 0) {
            // If the scrollHeight is 0, then the element probably has display:none or is detached from the DOM.
            return;
        }

        // disallow vertical resizing
        if (computed.resize === 'vertical') {
            ta.style.resize = 'none';
        } else if (computed.resize === 'both') {
            ta.style.resize = 'horizontal';
        }

        let restoreScrollTops

        function cacheScrollTops(el) {
            const arr = [];

            while (el && el.parentNode && el.parentNode instanceof Element) {
                if (el.parentNode.scrollTop) {
                    arr.push([el.parentNode, el.parentNode.scrollTop]);
                }
                el = el.parentNode;
            }

            return () => arr.forEach(([node, scrollTop]) => {
                node.style.scrollBehavior = 'auto';
                node.scrollTop = scrollTop;
                node.style.scrollBehavior = null;
            });
        }
        // remove inline height style to accurately measure situations where the textarea should shrink
        // however, skip this step if the new value appends to the previous value, as textarea height should only have grown
        if (testForHeightReduction) {
            // ensure the scrollTop values of parent elements are not modified as a consequence of shrinking the textarea height
            restoreScrollTops = cacheScrollTops(ta);
            ta.style.height = '';
        }

        let newHeight;

        if (computed.boxSizing === 'content-box') {
            newHeight = ta.scrollHeight - (parseFloat(computed.paddingTop) + parseFloat(computed.paddingBottom));
        } else {
            newHeight = ta.scrollHeight + parseFloat(computed.borderTopWidth) + parseFloat(computed.borderBottomWidth);
        }

        if (computed.maxHeight !== 'none' && newHeight > parseFloat(computed.maxHeight)) {
            if (computed.overflowY === 'hidden') {
                ta.style.overflow = 'scroll';
            }
            newHeight = parseFloat(computed.maxHeight);
        } else if (computed.overflowY !== 'hidden') {
            ta.style.overflow = 'hidden';
        }

        ta.style.height = newHeight + 'px';

        if (restoreTextAlign) {
            ta.style.textAlign = restoreTextAlign;
        }

        if (restoreScrollTops) {
            restoreScrollTops();
        }

        if (this.previousHeight !== newHeight) {
            ta.dispatchEvent(new Event('autosize:resized', { bubbles: true }));
            this.previousHeight = newHeight;
        }

        if (initialOverflowY !== computed.overflow && !restoreTextAlign) {
            const textAlign = computed.textAlign;

            if (computed.overflow === 'hidden') {
                // Webkit fails to reflow text after overflow is hidden,
                // even if hiding overflow would allow text to fit more compactly.
                // The following is intended to force the necessary text reflow.
                ta.style.textAlign = textAlign === 'start' ? 'end' : 'start';
            }

            this.setHeight(ta, {
                restoreTextAlign: textAlign,
                testForHeightReduction: true,
            });
        }
    }

    itemBlurred() {
        // update once again explicitely, because in some cases the simple value update does not work
        if (!this.item || !this.response || this.readonly) return;
        if (typeof this.value === "undefined") return;

        let fi = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, true);

        if (!String(this.value) || String(this.value).trim() === "") {
            if (fi) {
                delete fi.answer;
                delete fi.text;
            }

            return;
        }

        let answer: any = undefined;

        if (this.item.type === fhirEnums.QuestionnaireItemType.decimal) {
            answer = {
                valueDecimal: parseFloat(this.value)
            };
        } else if (this.item.type === fhirEnums.QuestionnaireItemType.integer) {
            answer = {
                valueInteger: parseInt(this.value)
            };
        } else {
            answer = {
                valueString: this.value
            };
        }

        fi.answer = [answer];
    }

    responseChanged() {
        this.getInitialValue();
    }

    itemChanged() {
        this.getInitialValue();
        if (this.item?.type === "text") {
            window.addEventListener("resize", () => this.adjustHeight());
        }
    }

    detached() {
        this.hidePopupMenu();
        if (this.item?.type === "text") {
            window.removeEventListener("resize", () => this.adjustHeight());
        }
    }

    openTextBlockDialogue() {
        App.Instance.openTextBlock(this);
    }

    get isCalulcatedField() {
        if (typeof this._isCalulcatedField === "boolean") return this._isCalulcatedField;

        if (!this.item) return false;

        this._isCalulcatedField = false;

        if (this.item && this.item.extension) {
            let extCalc = this.item.extension.find(o => o.url.endsWith('questionnaire-calculated-field') && o.valueString);
            if (extCalc) {
                this.item.initialCoding = {
                    code: "=" + extCalc.valueString,
                };
            }
        }

        if (this.item && this.item.initialCoding && this.item.initialCoding.code) {
            let tmp = this.item.initialCoding.code.trim();
            if (tmp.indexOf("=") === 0) {
                this._isCalulcatedField = true;
            }
        }

        return this._isCalulcatedField;
    }

    maxLength: number = NaN;

    get hasMax() {
        return (typeof this.item?.maxLength === "number" && this.item.maxLength && typeof this.maxLength === "number" && this.maxLength > 0);
    }

    get requiredText(): string {
        return this.required ? 'required="required"' : '';
    }

    attached() {
        this.adjustHeight();
    }

    getInitialValue() {
        // if (!this.isCalulcatedField) return;
        if (!this.item || !this.response) {
            this.responseItem = undefined;
            this.value = "";
            return;
        }

        this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, true);
        this.value = this.responseItem?.answer?.[0] ? Fhir.QuestionnaireResponse.GetItemAnswer(this.responseItem.answer[0], undefined) : undefined;
        this.readonlyBySetting = NitTools.ParseBool(this.item.readOnly);
        this.required = NitTools.ParseBool(this.item.required);
        if (typeof this.item.maxLength !== "undefined") {
            this.maxLength = this.item.maxLength;
        }

        if (!this.isCalulcatedField && // no initialvalue on calc fields and not when the item has an answer
            this.response.status === 'in-progress' &&
            !this.value && NitTools.IsArray(this.item.initial) && this.response?.status === 'in-progress') {
            
            if (this.item.initial[0]) {
                this.value = this.item.initialString || this.item.initialText;
            }
            /* if (this.item.initialCoding && this.item.initialCoding.code) {
                this.value = this.item.initialCoding.code;
            } */
        }

        if (this.isCalulcatedField) {
            this.inputType = "text";
        } else {
            switch (this.item.type) {
                default:
                case "text":
                    this.inputType = "text";
                    break;
                case "integer":
                case "decimal":
                    this.inputType = "number";
                    break;
                case "date":
                    console.warn("Date-Input should never appear instead it should be handeled by q-date control");
                    this.inputType = "text";
                    break;
                case "time":
                    this.inputType = RuntimeInfo.IsIE ? "text" : "time";
                    break;
                case "dateTime":
                    this.inputType = RuntimeInfo.IsIE ? "text" : "datetime-local";
                    break;
            }
        }
    }
}
